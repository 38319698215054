import { useRouter } from 'next/dist/client/router'
// import { LoginMethod } from '@components/login'
import { StaticContentConst } from '@components/static-content'
import { shopHelpPath } from '@/helpers'
import { useRedirect } from '@hooks/useRedirect'

export const HomePageHeader: React.FC = ({ ...props }) => {
  const router = useRouter()

  // function scrollToApp() {
  //   const appElement = document.getElementById('subscribe-shophelp')
  //   appElement?.scrollIntoView({ behavior: 'smooth' })
  // }

  const redirect = useRedirect()

  return (
    <div {...props} className="homepage-header">
      <style jsx>{`
        .homepage-header {
          font-size: 16px;
          line-height: 32px;

          .container {
            min-height: 120px;
            @media screen and (max-width: 1023px) {
              min-height: 80px;
            }
            width: 100%;

            p {
              font-weight: 500;
            }

            button {
              background: var(--secondary-100);
              min-width: 120px;
              border: none;
              color: var(--white-100);
              font-weight: 500;
              font-size: 14px;
              border-radius: 8px;
              line-height: 17px;

              padding: 16px 40px;
              height: 50px;
            }
          }
        }
      `}</style>
      <div className="container is-flex is-align-items-center">
        <div className="is-hidden-desktop ml-auto mr-auto">
          <figure className="image is176x40 has-pointer mr-4" onClick={() => router.push('/')}>
            <img src={shopHelpPath('logo.png')} alt="ShopHelp Logo" />
          </figure>
        </div>
        <div className="is-inline-flex is-hidden-touch is-align-items-center" style={{ flex: 1 }}>
          <figure className="image is176x40 has-pointer mr-4" onClick={() => router.push('/')}>
            <img src={shopHelpPath('logo.png')} alt="ShopHelp Logo" />
          </figure>
          <p
            className="has-pointer ml-8 mr-8 is-hidden-touch"
            onClick={
              () => redirect(`/policies/${StaticContentConst.ABOUT_US}`)
              //  router.push(`/policies/${StaticContentConst.ABOUT_US}`)
            }
          >
            What ShopHelp does
          </p>
          <p
            className="has-pointer ml-8"
            onClick={() => router.push(`/policies/${StaticContentConst.TERM_OF_SERVICE}`)}
          >
            Terms of services
          </p>
        </div>
        <div className="is-hidden-touch is-inline-flex is-justify-content-end is-align-items-center">
          {/* <a
            target="_blank"
            rel="noreferrer"
            className="mr-8"
            style={{ color: 'var(--gray-9)', fontWeight: 500 }}
            onClick={() => scrollToApp()}
          >
            Download app{' '}
          </a> */}
          <button className="button" onClick={() => router.push('/detail')}>
            Check your order
          </button>
        </div>
      </div>
    </div>
  )
}
