import { SVGeCommerceDeals, SVGeCustomerService } from '.'
import { StaticContentConst } from '@components/static-content'
import { useRouter } from 'next/dist/client/router'
import { shopHelpPath } from '@/helpers'

export const HomePageStaticBody: React.FC = () => {
  const router = useRouter()

  return (
    <>
      <style jsx>{`
        .homepage-static {
          font-size: 16px;
          line-height: 32px;
          h2 {
            font-size: 20px;
            line-height: 32px;
          }
          section {
            margin: 4rem 8rem 0;
          }
          @media screen and (min-width: 1024px) {
            h2 {
              margin: 0 14rem;
              font-size: 2rem;
              line-height: 3rem;
            }
            .m-top {
              margin-top: 7%;
            }
          }
          @media screen and (max-width: 1023px) {
            &.m-top {
              margin-top: 7rem;
            }

            section {
              margin: 0;
              margin-top: 6rem;
            }
            .column {
              padding: 0;
              &:first-child {
                margin-bottom: 5rem;
              }
            }
          }
        }
      `}</style>
      <div className="m-top container has-text-centered homepage-static">
        <h2 className="has-text-weight-semibold mb-2">
          Shophelp connects millions of users to merchants in our networks all over the world.
        </h2>
        <div
          className="is-link has-text-weight-semibold is-flex is-align-items-center is-justify-content-center"
          onClick={() => router.push(`/policies/${StaticContentConst.ABOUT_US}`)}
        >
          Learn more what ShopHelp does{' '}
          <figure className="image ml-2" style={{ width: 12 }}>
            <img
              width={16}
              height={16}
              src={shopHelpPath('arrow_right.svg')}
              alt="Right"
              style={{
                filter:
                  'invert(40%) sepia(27%) saturate(7499%) hue-rotate(187deg) brightness(97%) contrast(93%)'
              }}
            />
          </figure>
        </div>
        <section className="columns">
          <div className="column has-text-centered">
            {SVGeCommerceDeals}
            <p className="mt-4 mb-4 has-text-weight-semibold text-large-ex">eCommerce deals</p>
            <p style={{ maxWidth: 385 }} className="ml-auto mr-auto">
              Shophelp runs its own stores and partners with online retailers in delivering best
              deals to customers
            </p>
          </div>
          <div className="column has-text-centered">
            {SVGeCustomerService}
            <p className="mt-4 mb-4 has-text-weight-semibold text-large-ex">
              Excellent customer service
            </p>
            <p style={{ maxWidth: 450 }} className="ml-auto mr-auto">
              Our #1 priority is your happiness. We want our customers to have a superior buying
              experience, every time
            </p>
          </div>
        </section>
      </div>
    </>
  )
}
