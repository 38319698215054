import { CSSProperties } from 'react'
import css from 'styled-jsx/css'

type ToolTipProps = {
  active?: boolean
  backgroundColor?: string
  contentColor?: string
  alignArrowLeft?: number
  containerContent: string | JSX.Element
  customStyleArrow?: CSSProperties
  tooltipContentStyle?: CSSProperties
  customStyle?: CSSProperties
}

const Tooltip: React.FC<ToolTipProps> = ({
  active = true,
  backgroundColor = 'var(--gray-8)',
  contentColor = 'var(--white-100)',
  alignArrowLeft = 50,
  containerContent,
  children,
  tooltipContentStyle,
  customStyleArrow,
  customStyle
}) => {
  return (
    <>
      <style jsx>{toolTipStyle}</style>
      <style jsx>{`
        .tooltip__content {
          background-color: ${backgroundColor};
          color: ${contentColor};

          &--arrow {
            background: ${backgroundColor};
            left: ${alignArrowLeft + '%'};
          }
        }
      `}</style>
      <div className={`tooltip${active ? '' : ' no-hover'}`} style={customStyle}>
        {containerContent}
        <span className="tooltip__content" style={tooltipContentStyle}>
          {children}
          <span className="tooltip__content--arrow" style={customStyleArrow}></span>
        </span>
      </div>
    </>
  )
}

const toolTipStyle = css`
  .tooltip {
    position: relative;
    display: flex;
    justify-content: center;

    &__content {
      visibility: hidden;
      border-radius: 4px;
      padding: 8px 24px;
      position: absolute;
      z-index: 1;
      text-align: center;
      bottom: calc(100% + 10px);

      &--arrow {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        bottom: -5.64px;
        border-radius: 3px;
        transform: rotate(-45deg);
      }
    }

    &:hover .tooltip__content {
      visibility: visible;
      opacity: 1;
    }
  }

  .no-hover {
    pointer-events: none;
  }
`

export default Tooltip
