export const SVGeCommerceDeals = (
  <svg
    width="264"
    height="204"
    viewBox="0 0 264 204"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M11.708 198.181C19.9368 204.1 30.6008 204.397 40.9087 203.67C42.3481 203.569 43.7773 203.45 45.1932 203.321C45.2017 203.319 45.213 203.32 45.2217 203.318C45.2897 203.311 45.3579 203.305 45.423 203.301C45.7153 203.274 46.0076 203.246 46.2972 203.219L46.2288 203.349L46.0141 203.753C46.0913 203.618 46.1683 203.486 46.2455 203.35C46.2682 203.31 46.2936 203.269 46.3162 203.229C48.9865 198.589 51.6349 193.778 52.3105 188.48C53.0088 182.98 51.0463 176.753 46.199 174.05C45.5633 173.697 44.8901 173.415 44.1919 173.211C43.902 173.123 43.6082 173.051 43.311 172.988C41.4931 172.585 39.6004 172.673 37.8277 173.242C36.055 173.812 34.4661 174.842 33.2245 176.228C31.9829 177.614 31.1333 179.305 30.7632 181.127C30.3931 182.95 30.5158 184.838 31.1186 186.597C27.009 182.389 27.7756 175.563 29.1932 169.858C30.6136 164.153 32.3331 157.805 29.4442 152.681C27.8383 149.829 25.0143 148.059 21.845 147.346C21.7478 147.326 21.6507 147.305 21.5533 147.287C17.7772 146.523 13.8515 147.21 10.5605 149.21C4.50057 153.023 1.38813 160.253 0.377962 167.335C-1.24961 178.731 2.35222 191.45 11.708 198.181Z"
        fill="#F2F2F2"
      />
      <path
        d="M14.3648 179.912C14.6037 182.426 15.2084 184.892 16.1599 187.231C17.0368 189.302 18.2188 191.229 19.6666 192.951C22.6241 196.503 26.5348 199.174 30.782 200.954C34.0294 202.282 37.432 203.195 40.9087 203.671C42.3481 203.569 43.7772 203.45 45.1932 203.322C45.2017 203.32 45.213 203.32 45.2217 203.318C45.2897 203.312 45.3579 203.305 45.423 203.301C45.7153 203.274 46.0076 203.247 46.2971 203.219L46.2288 203.35L46.0141 203.754C46.0912 203.619 46.1683 203.486 46.2455 203.351C46.2682 203.31 46.2936 203.27 46.3161 203.229C43.7096 200.636 41.7636 197.457 40.641 193.958C39.5184 190.459 39.252 186.743 39.8641 183.12C40.5046 179.523 41.9875 176.128 44.1919 173.211C43.902 173.123 43.6082 173.052 43.311 172.989C42.4733 174.132 41.7384 175.348 41.1151 176.621C38.8783 181.16 38.1647 186.298 39.0799 191.274C39.9307 195.776 42.0504 199.943 45.1901 203.285C44.9152 203.266 44.6376 203.243 44.366 203.219C39.2206 202.783 34.0857 201.679 29.4187 199.416C25.4295 197.529 21.9663 194.692 19.3339 191.154C16.5074 187.261 15.262 182.579 15.048 177.821C14.8211 172.726 15.4831 167.543 16.6475 162.588C17.8364 157.509 19.6432 152.595 22.0278 147.954C22.0811 147.849 22.0919 147.727 22.0578 147.614C22.0238 147.501 21.9476 147.405 21.845 147.347C21.7595 147.289 21.6546 147.268 21.5533 147.287C21.5037 147.3 21.4577 147.324 21.4187 147.358C21.3797 147.391 21.3488 147.433 21.3282 147.48C21.0341 148.053 20.7454 148.627 20.4677 149.207C18.1547 154.038 16.4519 159.138 15.3983 164.389C14.3755 169.455 13.8295 174.754 14.3648 179.912Z"
        fill="white"
      />
      <path
        d="M258.706 175.39H122.609C121.205 175.389 119.86 174.831 118.867 173.84C117.875 172.849 117.316 171.505 117.315 170.104V5.28659C117.316 3.88498 117.875 2.54123 118.867 1.55015C119.86 0.559062 121.205 0.00157785 122.609 0H258.706C260.109 0.00157785 261.455 0.559062 262.447 1.55015C263.44 2.54123 263.998 3.88498 264 5.28659V170.104C263.998 171.505 263.44 172.849 262.447 173.84C261.455 174.831 260.109 175.389 258.706 175.39ZM122.609 0.621951C121.371 0.62335 120.183 1.11525 119.307 1.98973C118.432 2.86422 117.939 4.04988 117.938 5.28659V170.104C117.939 171.34 118.432 172.526 119.307 173.401C120.183 174.275 121.371 174.767 122.609 174.768H258.706C259.944 174.767 261.131 174.275 262.007 173.401C262.883 172.526 263.376 171.34 263.377 170.104V5.28659C263.376 4.04988 262.883 2.86422 262.007 1.98973C261.131 1.11525 259.944 0.62335 258.706 0.621951H122.609Z"
        fill="#3F3D56"
      />
      <path d="M263.689 12.9336H117.626V13.5555H263.689V12.9336Z" fill="#3F3D56" />
      <path
        d="M124.789 9.32913C126.165 9.32913 127.281 8.2153 127.281 6.84132C127.281 5.46734 126.165 4.35352 124.789 4.35352C123.413 4.35352 122.298 5.46734 122.298 6.84132C122.298 8.2153 123.413 9.32913 124.789 9.32913Z"
        fill="#3F3D56"
      />
      <path
        d="M132.575 9.32913C133.951 9.32913 135.066 8.2153 135.066 6.84132C135.066 5.46734 133.951 4.35352 132.575 4.35352C131.199 4.35352 130.083 5.46734 130.083 6.84132C130.083 8.2153 131.199 9.32913 132.575 9.32913Z"
        fill="#3F3D56"
      />
      <path
        d="M140.361 9.32913C141.737 9.32913 142.852 8.2153 142.852 6.84132C142.852 5.46734 141.737 4.35352 140.361 4.35352C138.985 4.35352 137.869 5.46734 137.869 6.84132C137.869 8.2153 138.985 9.32913 140.361 9.32913Z"
        fill="#3F3D56"
      />
      <path
        d="M237.609 37.6278H140.442C139.368 37.6278 138.338 37.2019 137.579 36.4438C136.82 35.6856 136.393 34.6574 136.393 33.5852C136.393 32.513 136.82 31.4847 137.579 30.7266C138.338 29.9684 139.368 29.5425 140.442 29.5425H237.609C238.683 29.5425 239.713 29.9684 240.472 30.7266C241.231 31.4847 241.658 32.513 241.658 33.5852C241.658 34.6574 241.231 35.6856 240.472 36.4438C239.713 37.2019 238.683 37.6278 237.609 37.6278ZM140.442 30.1644C139.533 30.1644 138.662 30.5248 138.019 31.1663C137.377 31.8079 137.016 32.6779 137.016 33.5852C137.016 34.4924 137.377 35.3625 138.019 36.004C138.662 36.6455 139.533 37.0059 140.442 37.0059H237.609C238.518 37.0059 239.389 36.6455 240.031 36.004C240.674 35.3625 241.035 34.4924 241.035 33.5852C241.035 32.6779 240.674 31.8079 240.031 31.1663C239.389 30.5248 238.518 30.1644 237.609 30.1644H140.442Z"
        fill="#3F3D56"
      />
      <path
        d="M176.799 94.5364H138.492C137.089 94.5348 135.743 93.9773 134.75 92.9862C133.758 91.9952 133.2 90.6514 133.198 89.2498V57.8413C133.2 56.4397 133.758 55.0959 134.75 54.1048C135.743 53.1138 137.089 52.5563 138.492 52.5547H176.799C178.202 52.5563 179.548 53.1138 180.541 54.1048C181.533 55.0959 182.091 56.4397 182.093 57.8413V89.2498C182.091 90.6514 181.533 91.9952 180.541 92.9862C179.548 93.9773 178.202 94.5348 176.799 94.5364ZM138.492 53.1766C137.254 53.178 136.066 53.6699 135.191 54.5444C134.315 55.4189 133.822 56.6046 133.821 57.8413V89.2498C133.822 90.4865 134.315 91.6722 135.191 92.5466C136.066 93.4211 137.254 93.913 138.492 93.9144H176.799C178.037 93.913 179.225 93.4211 180.1 92.5466C180.976 91.6722 181.469 90.4865 181.47 89.2498V57.8413C181.469 56.6046 180.976 55.4189 180.1 54.5444C179.225 53.6699 178.037 53.178 176.799 53.1766H138.492Z"
        fill="#3F3D56"
      />
      <path
        d="M242.822 54.1099H204.516C201.764 54.1099 199.533 56.3375 199.533 59.0855V90.494C199.533 93.242 201.764 95.4696 204.516 95.4696H242.822C245.574 95.4696 247.805 93.242 247.805 90.494V59.0855C247.805 56.3375 245.574 54.1099 242.822 54.1099Z"
        fill="#F2F2F2"
      />
      <path
        d="M176.799 153H138.492C137.089 152.998 135.743 152.441 134.75 151.45C133.758 150.459 133.2 149.115 133.198 147.713V116.305C133.2 114.903 133.758 113.559 134.75 112.568C135.743 111.577 137.089 111.02 138.492 111.018H176.799C178.202 111.02 179.548 111.577 180.541 112.568C181.533 113.559 182.091 114.903 182.093 116.305V147.713C182.091 149.115 181.533 150.459 180.541 151.45C179.548 152.441 178.202 152.998 176.799 153ZM138.492 111.64C137.254 111.641 136.066 112.133 135.191 113.008C134.315 113.882 133.822 115.068 133.821 116.305V147.713C133.822 148.95 134.315 150.136 135.191 151.01C136.066 151.885 137.254 152.376 138.492 152.378H176.799C178.037 152.376 179.225 151.885 180.1 151.01C180.976 150.136 181.469 148.95 181.47 147.713V116.305C181.469 115.068 180.976 113.882 180.1 113.008C179.225 112.133 178.037 111.641 176.799 111.64H138.492Z"
        fill="#3F3D56"
      />
      <path
        d="M242.822 112.573H204.516C201.764 112.573 199.533 114.801 199.533 117.549V148.957C199.533 151.705 201.764 153.933 204.516 153.933H242.822C245.574 153.933 247.805 151.705 247.805 148.957V117.549C247.805 114.801 245.574 112.573 242.822 112.573Z"
        fill="#F2F2F2"
      />
      <path
        d="M148.113 86.9405C148.106 86.9405 148.099 86.9403 148.092 86.94L143.735 86.7789C143.599 86.7748 143.47 86.7218 143.37 86.6296C143.27 86.5374 143.207 86.4123 143.193 86.2774C142.893 83.6057 141.659 69.9913 146.829 64.3326C147.56 63.5156 148.465 62.8715 149.477 62.4469C150.489 62.0224 151.583 61.8282 152.68 61.8784C152.724 61.8812 152.769 61.8718 152.808 61.8512C152.848 61.8306 152.881 61.7996 152.904 61.7615L153.959 60.1064C154.017 60.0167 154.099 59.945 154.195 59.8993C154.291 59.8536 154.399 59.8358 154.505 59.8479C155.351 59.95 159.661 60.4316 161.474 59.9076C161.581 59.8761 161.694 59.8768 161.801 59.9095C161.907 59.9423 162.002 60.0056 162.073 60.0916L163.458 61.782C163.482 61.8125 163.512 61.837 163.547 61.8533C163.583 61.8696 163.621 61.8772 163.66 61.8755C164.364 61.8614 166.835 61.9976 168.996 64.4121C171.337 67.0265 173.753 72.9253 171.969 86.1791C171.95 86.3153 171.883 86.4401 171.779 86.5307C171.676 86.6212 171.543 86.6713 171.405 86.6719H167.974C167.837 86.6713 167.706 86.622 167.603 86.533C167.5 86.444 167.432 86.3211 167.412 86.1866L166.291 78.4632C166.282 78.3997 166.249 78.342 166.199 78.3015C166.149 78.261 166.086 78.2408 166.022 78.2447C165.958 78.2486 165.897 78.2765 165.853 78.3228C165.808 78.3691 165.783 78.4303 165.782 78.4945L165.704 82.1207C165.702 82.2282 165.669 82.3329 165.609 82.4225C165.55 82.5121 165.466 82.5828 165.367 82.6266C164.144 83.1637 157.68 85.6826 150.536 82.5278C150.439 82.4844 150.357 82.4151 150.297 82.3276C150.238 82.2401 150.204 82.1378 150.199 82.0321L150.058 78.7374C150.056 78.6731 150.029 78.6122 149.983 78.5668C149.937 78.5215 149.876 78.4951 149.811 78.4928C149.747 78.4906 149.684 78.5127 149.635 78.5547C149.586 78.5968 149.555 78.6557 149.548 78.7197L148.677 86.4373C148.661 86.5755 148.595 86.703 148.491 86.7957C148.387 86.8884 148.252 86.9399 148.113 86.9405Z"
        fill="#0985E3"
      />
      <path
        d="M166.137 143.839H166.134L149.06 143.731C148.936 143.73 148.817 143.684 148.724 143.601C148.632 143.518 148.573 143.405 148.558 143.282L146.84 128.705C146.83 128.611 146.786 128.524 146.716 128.46C146.647 128.396 146.556 128.359 146.461 128.356C145.106 128.312 141.881 128.004 141.213 126.098C140.618 124.4 142.246 121.896 146.051 118.656C146.116 118.6 146.193 118.562 146.277 118.544C146.36 118.527 146.446 118.53 146.528 118.555C146.61 118.579 146.684 118.623 146.744 118.684C146.804 118.744 146.848 118.818 146.872 118.9L148.847 125.706C148.872 125.787 148.921 125.859 148.989 125.91C149.057 125.962 149.14 125.99 149.225 125.991L165.413 126.1C165.498 126.1 165.581 126.074 165.649 126.024C165.718 125.974 165.769 125.903 165.794 125.823L167.992 118.839C168.016 118.761 168.059 118.69 168.117 118.632C168.175 118.574 168.245 118.531 168.323 118.506C168.401 118.482 168.484 118.476 168.565 118.49C168.645 118.504 168.721 118.537 168.786 118.587C170.286 119.731 175.118 123.658 174.323 126.17C173.906 127.485 172.028 128.218 168.739 128.349C168.644 128.352 168.554 128.389 168.485 128.453C168.416 128.517 168.372 128.604 168.361 128.698L166.643 143.389C166.628 143.513 166.569 143.627 166.476 143.71C166.382 143.793 166.262 143.839 166.137 143.839Z"
        fill="#0985E3"
      />
      <path
        d="M240.954 82.4563C235.589 83.4876 230.529 85.725 226.159 88.9989C225.938 88.4114 225.522 87.9176 224.98 87.5999C224.609 87.3707 224.205 87.2012 223.782 87.0976C223.397 86.9997 223.028 87.2333 222.688 87.5698C222.298 88.0014 221.97 88.4859 221.715 89.0089C215.852 84.9904 206.958 82.4262 206.958 82.4262V81.6224C206.965 81.433 206.984 81.2443 207.016 81.0574C207.046 80.8414 207.084 80.6505 207.131 80.4169C207.886 76.5843 210.869 67.8265 210.869 67.8265H214.242L220.782 69.1827L228.139 68.7332L235.549 67.1936L236.509 66.9927C238.112 68.2485 239.93 77.0389 240.644 80.7811C240.735 81.2558 240.805 81.6476 240.858 81.9364C240.921 82.2629 240.954 82.4563 240.954 82.4563Z"
        fill="white"
      />
      <path
        d="M220.828 121.037C220.828 121.037 219.261 121.428 216.831 128C214.402 134.571 213.541 143.061 213.541 143.061C213.541 143.061 212.175 145.372 218.006 145.526C218.006 145.526 217.537 147.052 220.828 146.818C224.118 146.583 223.645 146.586 223.645 146.586C223.645 146.586 225.76 145.256 226.701 145.49C227.641 145.724 228.229 145.804 229.246 146.663C230.264 147.523 233.518 147.172 233.557 146.508C233.583 146.182 233.583 145.855 233.557 145.528C233.557 145.528 238.533 145.842 237.592 142.243C236.652 138.643 235.868 132.736 235.868 132.736C235.868 132.736 233.323 121.196 230.421 121.04C227.52 120.883 220.828 121.037 220.828 121.037Z"
        fill="white"
      />
      <path
        d="M59.9946 109.96C65.1546 109.96 69.3376 105.783 69.3376 100.631C69.3376 95.4781 65.1546 91.3013 59.9946 91.3013C54.8346 91.3013 50.6516 95.4781 50.6516 100.631C50.6516 105.783 54.8346 109.96 59.9946 109.96Z"
        fill="#2F2E41"
      />
      <path
        d="M60.7795 109.977C65.004 109.977 68.4286 106.558 68.4286 102.34C68.4286 98.1213 65.004 94.7017 60.7795 94.7017C56.555 94.7017 53.1304 98.1213 53.1304 102.34C53.1304 106.558 56.555 109.977 60.7795 109.977Z"
        fill="#FFB8B8"
      />
      <path
        d="M89.9104 125.571C89.7889 125.693 89.6773 125.825 89.5765 125.964L74.1411 125.42L72.3757 122.114L67.0308 124.122L69.5865 130.278C69.793 130.775 70.1554 131.192 70.6195 131.466C71.0835 131.741 71.624 131.857 72.16 131.798L89.6266 129.883C90.0324 130.408 90.5885 130.797 91.2211 130.998C91.8536 131.199 92.5325 131.203 93.1675 131.01C93.8025 130.817 94.3634 130.435 94.7758 129.915C95.1882 129.396 95.4324 128.763 95.4759 128.102C95.5194 127.44 95.3603 126.781 95.0196 126.212C94.6788 125.643 94.1727 125.192 93.5686 124.917C92.9644 124.642 92.2908 124.558 91.6373 124.675C90.9838 124.791 90.3815 125.104 89.9104 125.571H89.9104Z"
        fill="#FFB8B8"
      />
      <path
        d="M64.9749 127.095C64.7782 127.006 64.6028 126.877 64.4608 126.715C64.3188 126.553 64.2134 126.363 64.1519 126.156L62.002 118.951C61.5351 117.953 61.4836 116.811 61.8586 115.776C62.2336 114.74 63.0047 113.895 64.0026 113.426C65.0006 112.957 66.1441 112.903 67.1823 113.274C68.2206 113.646 69.0689 114.413 69.5413 115.409L73.7058 121.701C73.8247 121.881 73.9033 122.084 73.9365 122.297C73.9697 122.509 73.9565 122.727 73.898 122.934C73.8395 123.141 73.7369 123.333 73.5973 123.497C73.4577 123.661 73.2843 123.793 73.089 123.884L66.2241 127.085C66.0288 127.176 65.8161 127.225 65.6005 127.226C65.3849 127.228 65.1715 127.183 64.9749 127.095Z"
        fill="#CCCCCC"
      />
      <path
        d="M80.1774 200.246L83.531 198.423L78.0855 184.64L73.1362 187.33L80.1774 200.246Z"
        fill="#FFB8B8"
      />
      <path
        d="M78.7261 199.617L85.3301 196.027L85.3304 196.027C85.8831 195.727 86.4897 195.538 87.1154 195.472C87.7412 195.405 88.3739 195.463 88.9774 195.64C89.581 195.818 90.1436 196.113 90.633 196.508C91.1225 196.903 91.5292 197.39 91.8301 197.942L91.9045 198.079L81.0917 203.956L78.7261 199.617Z"
        fill="#2F2E41"
      />
      <path
        d="M43.2763 200.261H47.0942L48.9108 185.555L43.2754 185.555L43.2763 200.261Z"
        fill="#FFB8B8"
      />
      <path
        d="M42.3025 199.016L49.8217 199.016H49.822C50.4513 199.016 51.0744 199.139 51.6558 199.38C52.2371 199.62 52.7654 199.973 53.2104 200.417C53.6553 200.861 54.0083 201.389 54.2491 201.969C54.4899 202.55 54.6139 203.172 54.6138 203.8V203.956L42.3027 203.956L42.3025 199.016Z"
        fill="#2F2E41"
      />
      <path
        d="M67.0021 134.993L68.2478 149.92L82.1435 191.546C82.1982 191.71 82.2181 191.883 82.2021 192.055C82.186 192.226 82.1344 192.393 82.0504 192.544C81.9663 192.695 81.8518 192.826 81.7141 192.931C81.5763 193.035 81.4184 193.109 81.2503 193.149L76.9914 194.162C76.7187 194.227 76.432 194.198 76.1778 194.08C75.9236 193.962 75.7167 193.762 75.5905 193.512L57.825 158.324C57.7671 158.209 57.6751 158.115 57.5616 158.055C57.448 157.995 57.3185 157.971 57.1909 157.987C57.0633 158.003 56.9438 158.058 56.8488 158.145C56.7538 158.231 56.688 158.345 56.6605 158.471L48.8423 194.034C48.7814 194.311 48.6276 194.559 48.4063 194.737C48.185 194.915 47.9095 195.011 47.6255 195.011H43.6635C43.4985 195.012 43.3351 194.979 43.1828 194.915C43.0306 194.852 42.8925 194.759 42.7766 194.641C42.6608 194.524 42.5695 194.385 42.5081 194.232C42.4466 194.079 42.4163 193.915 42.4188 193.751C42.7819 169.091 41.5842 140.993 51.119 136.548L52.6761 132.505L67.0021 134.993Z"
        fill="#2F2E41"
      />
      <path
        d="M52.2275 133.564L50.6219 123.869C50.3562 122.279 50.4955 120.647 51.0272 119.125C51.5589 117.603 52.4658 116.239 53.6642 115.159C54.8357 114.09 56.2579 113.332 57.7999 112.956C59.3419 112.579 60.954 112.597 62.4875 113.006C62.5785 113.03 62.6696 113.055 62.7609 113.081C64.6477 113.62 66.3233 114.725 67.5613 116.246C68.7992 117.767 69.5399 119.63 69.6833 121.585C69.9897 125.795 69.7155 131.904 66.6691 137.093L66.61 137.194L52.2275 133.564Z"
        fill="#CCCCCC"
      />
      <path
        d="M68.3887 98.28C66.6408 99.483 64.5661 100.123 62.4432 100.115C63.2005 100.639 64.0626 100.993 64.9701 101.153C62.1476 101.757 59.2305 101.774 56.4013 101.201C55.7724 101.1 55.1656 100.892 54.6075 100.586C54.3294 100.431 54.0871 100.219 53.8963 99.964C53.7056 99.7093 53.5706 99.4173 53.5003 99.107C53.3123 98.0346 54.1492 97.0603 55.0189 96.4037C56.3504 95.4137 57.8857 94.7316 59.5136 94.4068C61.1416 94.082 62.8215 94.1225 64.4318 94.5255C65.4833 94.7969 66.5366 95.2554 67.2196 96.0986C67.9025 96.9418 68.1048 98.2417 67.4542 99.11L68.3887 98.28Z"
        fill="#2F2E41"
      />
      <path
        d="M59.8717 91.2551C60.7584 87.6802 57.5804 83.7084 53.8928 83.7828C50.1514 83.8582 47.3982 87.2342 45.5056 90.4577C43.6131 93.6812 41.7321 97.3354 38.2577 98.7232C37.1079 99.1825 35.862 99.3497 34.6316 99.2098C33.4012 99.0699 32.2248 98.6273 31.2079 97.9217C30.191 97.2161 29.3654 96.2695 28.805 95.1668C28.2446 94.0641 27.9671 92.8398 27.9972 91.6037C27.5307 95.5199 28.177 99.7572 30.7063 102.786C33.2356 105.815 37.9419 107.18 41.3247 105.145C43.2567 103.982 44.4916 101.961 45.5097 99.9513C46.5278 97.9413 47.4337 95.8218 48.9763 94.1785C50.5189 92.5351 52.9042 91.4444 55.051 92.1358C55.9032 92.4103 56.6677 92.9452 57.5481 93.108C58.4286 93.2708 59.537 92.853 59.6573 91.967L59.8717 91.2551Z"
        fill="#2F2E41"
      />
      <path
        d="M41.4358 156.076C41.1184 155.704 40.8863 155.268 40.7561 154.797C40.6258 154.326 40.6005 153.833 40.682 153.351C40.7634 152.87 40.9496 152.412 41.2274 152.01C41.5052 151.608 41.8679 151.272 42.29 151.025L48.9252 121.328L55.6561 124.086L47.0174 152.41C47.3856 153.146 47.4709 153.99 47.2571 154.784C47.0433 155.579 46.5452 156.267 45.8572 156.719C45.1692 157.17 44.3391 157.355 43.5243 157.236C42.7094 157.118 41.9663 156.705 41.4359 156.076H41.4358Z"
        fill="#FFB8B8"
      />
      <path
        d="M46.2961 125.588C46.1901 125.4 46.1259 125.192 46.1078 124.978C46.0897 124.763 46.1182 124.547 46.1913 124.345L48.7428 117.272C48.96 116.192 49.5971 115.242 50.5145 114.631C51.4319 114.019 52.5547 113.796 53.6366 114.01C54.7186 114.224 55.6715 114.857 56.2863 115.772C56.9011 116.686 57.1277 117.807 56.9164 118.888L56.5288 126.42C56.5177 126.635 56.4604 126.845 56.3607 127.036C56.2609 127.227 56.1212 127.394 55.951 127.526C55.7807 127.658 55.584 127.752 55.3742 127.801C55.1643 127.851 54.9463 127.854 54.735 127.812L47.3071 126.321C47.0957 126.278 46.896 126.191 46.7215 126.064C46.547 125.938 46.4019 125.775 46.2961 125.588Z"
        fill="#CCCCCC"
      />
      <path
        d="M100.66 122.257L100.549 122.242L100.966 119.218C101.03 118.754 100.906 118.284 100.623 117.91C100.339 117.537 99.9189 117.291 99.4541 117.228L93.0387 116.347C92.5739 116.283 92.1027 116.406 91.7289 116.689C91.355 116.973 91.1091 117.392 91.0452 117.856L88.7616 134.444C88.6977 134.908 88.8211 135.379 89.1046 135.752C89.3881 136.125 89.8085 136.371 90.2733 136.435L96.6887 137.315C97.1535 137.379 97.6246 137.256 97.9985 136.973C98.3723 136.69 98.6183 136.27 98.6821 135.806L100.253 124.394L100.364 124.409L100.66 122.257Z"
        fill="#3F3D56"
      />
      <path
        d="M99.2523 118.115L98.4536 118.005C98.4779 118.1 98.4785 118.2 98.4553 118.295C98.4321 118.39 98.3857 118.478 98.3204 118.552C98.255 118.625 98.1726 118.681 98.0805 118.715C97.9884 118.748 97.8893 118.759 97.7921 118.746L94.2864 118.265C94.1892 118.251 94.0967 118.214 94.0171 118.157C93.9376 118.099 93.8734 118.023 93.8303 117.935C93.7871 117.847 93.7663 117.75 93.7697 117.652C93.773 117.554 93.8005 117.458 93.8496 117.373L93.1036 117.271C92.9396 117.248 92.7728 117.258 92.6128 117.3C92.4527 117.342 92.3024 117.415 92.1705 117.515C92.0387 117.615 91.9278 117.74 91.8443 117.882C91.7607 118.025 91.7061 118.182 91.6835 118.346L89.5086 134.145C89.486 134.309 89.496 134.475 89.5379 134.635C89.5798 134.795 89.6529 134.945 89.7529 135.077C89.8529 135.208 89.9778 135.319 90.1207 135.403C90.2635 135.486 90.4214 135.54 90.5853 135.563L96.7341 136.407C97.0652 136.452 97.4008 136.365 97.6671 136.163C97.9334 135.961 98.1086 135.662 98.1541 135.332L100.329 119.533C100.375 119.202 100.287 118.867 100.085 118.601C99.8828 118.335 99.5834 118.16 99.2523 118.115L99.2523 118.115Z"
        fill="white"
      />
      <path
        d="M94.8916 131.232C96.7836 131.232 98.3174 129.7 98.3174 127.811C98.3174 125.922 96.7836 124.39 94.8916 124.39C92.9996 124.39 91.4658 125.922 91.4658 127.811C91.4658 129.7 92.9996 131.232 94.8916 131.232Z"
        fill="#0985E3"
      />
      <path
        d="M94.5521 129.116C94.475 129.116 94.4 129.092 94.3383 129.045L94.3345 129.042L93.5294 128.427C93.492 128.399 93.4605 128.363 93.4369 128.322C93.4132 128.282 93.3978 128.237 93.3916 128.19C93.3853 128.144 93.3884 128.096 93.4005 128.051C93.4126 128.005 93.4336 127.963 93.4622 127.926C93.4908 127.888 93.5265 127.857 93.5673 127.834C93.608 127.81 93.653 127.795 93.6997 127.789C93.7464 127.783 93.7938 127.786 93.8392 127.798C93.8847 127.81 93.9272 127.831 93.9645 127.86L94.486 128.259L95.7183 126.654C95.7469 126.617 95.7825 126.586 95.8232 126.563C95.8638 126.539 95.9087 126.524 95.9553 126.518C96.0019 126.512 96.0492 126.515 96.0946 126.527C96.14 126.539 96.1825 126.56 96.2198 126.588L96.2121 126.599L96.22 126.589C96.2952 126.646 96.3443 126.731 96.3568 126.825C96.3692 126.919 96.3438 127.014 96.2862 127.089L94.8367 128.977C94.8031 129.02 94.76 129.056 94.7107 129.08C94.6613 129.104 94.6071 129.116 94.5521 129.116Z"
        fill="white"
      />
      <path
        d="M126.465 204H7.80924C7.72664 204 7.64743 203.967 7.58902 203.909C7.53062 203.85 7.4978 203.771 7.4978 203.689C7.4978 203.606 7.53062 203.527 7.58902 203.469C7.64743 203.411 7.72664 203.378 7.80924 203.378H126.465C126.548 203.378 126.627 203.411 126.686 203.469C126.744 203.527 126.777 203.606 126.777 203.689C126.777 203.771 126.744 203.85 126.686 203.909C126.627 203.967 126.548 204 126.465 204Z"
        fill="#3F3D56"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="264" height="204" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const SVGeCustomerService = (
  <svg
    width="247"
    height="206"
    viewBox="0 0 247 206"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip1)">
      <path
        d="M116.493 195.854C116.175 195.861 115.867 195.969 115.614 196.164C114.865 194.18 113.705 192.904 112.402 192.904C111.098 192.904 109.938 194.18 109.189 196.164C108.936 195.969 108.628 195.861 108.31 195.854C106.829 195.854 105.629 198.363 105.629 201.459H119.174C119.174 198.363 117.974 195.854 116.493 195.854Z"
        fill="#0985E3"
      />
      <path
        d="M83.0916 195.854C82.773 195.861 82.4649 195.969 82.2124 196.164C81.4631 194.18 80.303 192.904 78.9998 192.904C77.6965 192.904 76.5365 194.18 75.7871 196.164C75.5346 195.969 75.2265 195.861 74.9079 195.854C73.4273 195.854 72.2271 198.363 72.2271 201.459H85.7725C85.7725 198.363 84.5722 195.854 83.0916 195.854Z"
        fill="#0985E3"
      />
      <path
        d="M212.954 195.854C212.635 195.861 212.327 195.969 212.075 196.164C211.325 194.18 210.165 192.904 208.862 192.904C207.559 192.904 206.399 194.18 205.649 196.164C205.397 195.969 205.089 195.861 204.77 195.854C203.29 195.854 202.089 198.363 202.089 201.459H215.635C215.635 198.363 214.435 195.854 212.954 195.854Z"
        fill="#0985E3"
      />
      <path d="M242.868 200.914H61.4976V201.54H242.868V200.914Z" fill="#3F3D56" />
      <path
        d="M116.053 201.459H96.8887V201.146C96.8887 196.669 98.6376 193.162 100.87 193.162C101.237 193.164 101.598 193.258 101.92 193.435C103.04 190.69 104.685 189.125 106.471 189.125C108.256 189.125 109.902 190.69 111.021 193.435C111.343 193.258 111.704 193.164 112.072 193.162C114.304 193.162 116.053 196.669 116.053 201.146V201.459ZM97.5162 200.833H115.426C115.348 196.983 113.844 193.788 112.072 193.788C111.701 193.797 111.344 193.925 111.051 194.152L110.721 194.392L110.576 194.01C109.554 191.303 108.058 189.75 106.471 189.75C104.884 189.75 103.388 191.303 102.366 194.01L102.221 194.392L101.891 194.152C101.598 193.925 101.24 193.797 100.87 193.788C99.0982 193.788 97.594 196.983 97.5162 200.833Z"
        fill="#3F3D56"
      />
      <path
        d="M227.81 201.459H208.645V201.146C208.645 196.669 210.394 193.162 212.627 193.162C212.994 193.164 213.355 193.258 213.677 193.435C214.796 190.69 216.442 189.125 218.227 189.125C220.013 189.125 221.659 190.69 222.778 193.435C223.1 193.258 223.461 193.164 223.828 193.162C226.061 193.162 227.81 196.669 227.81 201.146V201.459ZM209.272 200.833H227.182C227.104 196.983 225.6 193.788 223.828 193.788C223.458 193.797 223.1 193.925 222.808 194.152L222.477 194.392L222.333 194.01C221.31 191.303 219.814 189.75 218.227 189.75C216.641 189.75 215.144 191.303 214.122 194.01L213.978 194.392L213.647 194.152C213.354 193.925 212.997 193.797 212.627 193.788C210.855 193.788 209.35 196.983 209.272 200.833Z"
        fill="#3F3D56"
      />
      <path
        d="M85.1483 201.459H65.9839V201.146C65.9839 196.669 67.7328 193.162 69.9654 193.162C70.3326 193.164 70.6935 193.258 71.0156 193.435C72.1348 190.69 73.7805 189.125 75.566 189.125C77.3515 189.125 78.9974 190.69 80.1166 193.435C80.4387 193.258 80.7996 193.164 81.1668 193.162C83.3994 193.162 85.1483 196.669 85.1483 201.146V201.459ZM66.6114 200.833H84.5208C84.443 196.983 82.9387 193.788 81.1668 193.788C80.7965 193.797 80.439 193.925 80.1464 194.152L79.8158 194.392L79.6715 194.01C78.6492 191.303 77.1528 189.75 75.566 189.75C73.9793 189.75 72.483 191.303 71.4607 194.01L71.3164 194.392L70.9858 194.152C70.6932 193.925 70.3357 193.797 69.9654 193.788C68.1935 193.788 66.6892 196.983 66.6114 200.833Z"
        fill="#3F3D56"
      />
      <path
        d="M155.72 88.7564C155.779 88.6632 155.626 88.4602 155.378 88.303C155.13 88.1457 154.881 88.0937 154.822 88.1868C154.764 88.28 154.917 88.483 155.165 88.6402C155.412 88.7975 155.661 88.8495 155.72 88.7564Z"
        fill="#3F3D56"
      />
      <path
        d="M154.85 87.0234C154.982 86.8147 155.191 86.6671 155.431 86.6129C155.672 86.5588 155.924 86.6026 156.132 86.7348L167.912 94.21C168.12 94.3421 168.268 94.5518 168.322 94.7928C168.376 95.0339 168.332 95.2865 168.2 95.4953L154.85 87.0234Z"
        fill="#0985E3"
      />
      <path
        d="M153.736 87.4189L154.106 86.8325C154.197 86.6899 154.34 86.5889 154.504 86.552C154.668 86.515 154.841 86.5449 154.983 86.6353L168.395 95.1463C168.564 95.2533 168.683 95.423 168.727 95.6181C168.77 95.8132 168.735 96.0177 168.628 96.1866L168.32 96.6739L153.736 87.4189Z"
        fill="#3F3D56"
      />
      <path
        d="M155.733 87.741L155.591 87.9658L156.488 88.5354L156.63 88.3105L155.733 87.741Z"
        fill="#D0CDE1"
      />
      <path
        d="M156.967 88.5242L156.825 88.749L157.722 89.3186L157.864 89.0937L156.967 88.5242Z"
        fill="#D0CDE1"
      />
      <path
        d="M174.744 61.2092C179.368 61.2092 183.116 57.4527 183.116 52.8189C183.116 48.1851 179.368 44.4287 174.744 44.4287C170.12 44.4287 166.371 48.1851 166.371 52.8189C166.371 57.4527 170.12 61.2092 174.744 61.2092Z"
        fill="#FFB8B8"
      />
      <path
        d="M175.255 58.7535C175.255 58.7535 179.339 65.1997 178.318 67.0414C177.297 68.8832 174.131 73.8968 174.131 73.8968C174.131 73.8968 188.222 63.4602 186.384 61.2092C186.384 61.2092 182.198 57.628 181.993 55.377L175.255 58.7535Z"
        fill="#FFB8B8"
      />
      <path
        d="M181.789 181.941L182.197 188.387L188.732 187.261L188.324 181.02L181.789 181.941Z"
        fill="#FFB8B8"
      />
      <path
        d="M152.689 185.528L151.464 189.621L158.713 190.951L159.734 187.165L152.689 185.528Z"
        fill="#FFB8B8"
      />
      <path
        d="M182.453 95.1284L183.117 99.6817C183.117 99.6817 191.489 116.36 182.402 124.545L170.149 154.832C170.149 154.832 168.822 170.589 164.942 176.115L161.368 188.7L150.749 185.937L159.326 158.618C159.326 158.618 159.224 147.158 160.449 144.6L165.861 120.248L166.831 94.2076C166.831 94.2076 181.125 93.1844 182.453 95.1284Z"
        fill="#2F2E41"
      />
      <path
        d="M180.87 123.516L185.465 154.621C185.465 154.621 187.915 166.593 188.12 171.197C188.324 175.802 189.141 181.736 189.141 181.736L180.87 183.066L172.702 142.957L180.87 123.516Z"
        fill="#2F2E41"
      />
      <path
        d="M189.957 183.987L183.423 184.396C183.423 184.396 180.99 181.634 181.134 183.066C181.278 184.499 179.747 190.536 179.747 190.536L174.642 195.856C174.642 195.856 164.533 195.856 166.065 200.768C167.596 205.679 179.543 201.586 179.543 201.586L184.137 200.051V202.098C184.137 202.098 192 203.223 192.204 201.177C192.408 199.131 192.204 196.573 191.897 196.368C191.591 196.163 189.957 183.987 189.957 183.987Z"
        fill="#2F2E41"
      />
      <path
        d="M159.757 189.038C159.757 189.038 155.086 188.664 154.432 188.102C153.778 187.54 152.75 185.2 151.349 186.698C149.947 188.195 144.903 193.064 144.903 193.064L140.045 195.404C140.045 195.404 133.692 193.719 131.544 197.651C129.395 201.583 142.1 205.702 145.37 204.391L149.48 203.549L149.293 205.515C149.293 205.515 155.086 206.825 155.926 205.14C156.767 203.455 157.421 201.489 157.047 200.927C156.674 200.366 159.757 189.038 159.757 189.038Z"
        fill="#2F2E41"
      />
      <path
        d="M194.246 71.6459L187.013 87.6824L183.872 94.6443L182.708 97.2258L165.861 97.0212L167.597 85.2544C165.146 78.9106 170.966 76.3526 170.966 76.3526L175.867 71.1189V71.9528C175.867 71.9528 180.666 66.2229 182.81 62.9487C184.955 59.6744 187.916 60.9023 187.916 60.9023C193.021 60.3907 194.246 71.6459 194.246 71.6459Z"
        fill="#575A89"
      />
      <path
        opacity="0.2"
        d="M187.013 87.6825L183.872 94.6443C182.864 92.7535 182.096 90.6119 182.096 89.3472C182.096 85.8684 185.567 87.7101 185.567 87.7101C185.567 87.7101 182.759 72.311 182.759 67.7066C182.759 64.6483 185.304 78.221 187.013 87.6825Z"
        fill="black"
      />
      <path
        d="M184.24 88.8358L169.026 88.5288C169.026 88.5288 157.897 89.4497 159.53 93.9518C161.164 98.4538 170.047 93.9518 170.047 93.9518L187.814 96.7144L184.24 88.8358Z"
        fill="#FFB8B8"
      />
      <path
        d="M187.099 61.7207C187.099 61.7207 181.79 62.13 181.79 66.7344C181.79 71.3388 186.589 87.7099 186.589 87.7099C186.589 87.7099 183.117 85.8682 183.117 89.3471C183.117 92.8259 188.937 102.956 189.55 96.2025C189.55 96.2025 198.331 100.5 197.82 92.4167C197.31 84.3334 191.796 67.246 191.796 67.246C191.796 67.246 191.183 61.4137 187.099 61.7207Z"
        fill="#575A89"
      />
      <path
        d="M175.023 51.9062C174.436 51.3376 174.447 50.3786 173.975 49.7121C173.233 48.6653 171.688 48.7355 170.419 48.9162C169.571 48.9998 168.736 49.194 167.938 49.4939C167.588 49.6383 167.276 49.8623 167.027 50.1481C166.778 50.4339 166.598 50.7738 166.503 51.141C166.403 51.5534 166.184 50.3834 165.961 48.8682C165.733 47.3221 166 45.7432 166.725 44.3591C167.449 42.975 168.593 41.8571 169.992 41.1665C170.009 41.1583 170.025 41.1503 170.042 41.1423C171.477 40.4436 172.98 39.8265 174.566 39.6531C176.151 39.4797 177.844 39.7937 179.087 40.7947C179.656 41.2522 180.118 41.8395 180.728 42.2406C181.798 42.9452 183.154 42.9793 184.427 43.1279C185.875 43.2535 187.281 43.6856 188.551 44.3956C189.827 45.119 190.767 46.3174 191.167 47.7309C191.57 49.3755 190.913 51.3197 189.299 52.0334C189.017 52.1523 188.767 52.336 188.57 52.5697C187.245 54.1929 189.384 55.0811 189.694 56.494C189.953 57.6729 188.822 58.7404 187.661 59.065C186.569 59.337 185.417 59.2199 184.402 58.7338C183.387 58.2477 182.572 57.4229 182.097 56.4007C181.699 55.4759 181.608 54.4528 181.5 53.4515C181.376 52.3053 178.376 50.2293 180.401 51.7909C180.794 52.0858 181.104 52.4782 181.3 52.9295C181.916 54.4075 180.637 55.2464 179.951 56.3016C179.578 56.8951 179.378 57.5815 179.374 58.2831C179.37 58.9847 179.563 59.6732 179.93 60.2707C179.052 59.7782 178.374 58.9942 178.011 58.0539C177.649 57.1136 177.625 56.076 177.945 55.1202C178.129 54.6188 178.408 54.1552 178.575 53.6478C178.835 52.8561 178.605 51.3177 177.439 51.6296C176.607 51.8522 175.895 52.7527 175.023 51.9062Z"
        fill="#2F2E41"
      />
      <path
        d="M243.254 159.665C245.323 159.665 247 157.984 247 155.911C247 153.838 245.323 152.157 243.254 152.157C241.185 152.157 239.508 153.838 239.508 155.911C239.508 157.984 241.185 159.665 243.254 159.665Z"
        fill="#D0CDE1"
      />
      <path
        d="M233.889 174.368C237.337 174.368 240.132 171.567 240.132 168.111C240.132 164.656 237.337 161.855 233.889 161.855C230.441 161.855 227.646 164.656 227.646 168.111C227.646 171.567 230.441 174.368 233.889 174.368Z"
        fill="#0985E3"
      />
      <path d="M235.887 171.865H235.263V200.958H235.887V171.865Z" fill="#3F3D56" />
      <path
        d="M235.762 172.491C234.466 172.491 233.198 172.105 232.12 171.384C231.042 170.662 230.202 169.636 229.706 168.435C229.209 167.235 229.08 165.914 229.333 164.64C229.585 163.365 230.21 162.195 231.127 161.276C232.043 160.357 233.212 159.732 234.483 159.478C235.755 159.225 237.073 159.355 238.271 159.852C239.469 160.349 240.493 161.191 241.213 162.272C241.933 163.352 242.318 164.622 242.318 165.921C242.316 167.663 241.624 169.333 240.395 170.564C239.166 171.796 237.5 172.489 235.762 172.491ZM235.762 159.978C234.589 159.978 233.442 160.326 232.467 160.979C231.492 161.632 230.731 162.561 230.282 163.647C229.833 164.733 229.716 165.928 229.945 167.081C230.174 168.234 230.739 169.293 231.568 170.124C232.398 170.955 233.454 171.522 234.605 171.751C235.756 171.98 236.948 171.862 238.032 171.413C239.116 170.963 240.042 170.201 240.694 169.223C241.345 168.246 241.693 167.097 241.693 165.921C241.692 164.346 241.066 162.835 239.954 161.721C238.842 160.606 237.335 159.979 235.762 159.978Z"
        fill="#3F3D56"
      />
      <path
        d="M241.069 161.229C240.266 161.229 239.482 160.991 238.814 160.544C238.147 160.097 237.627 159.462 237.32 158.719C237.012 157.976 236.932 157.158 237.089 156.369C237.245 155.58 237.632 154.856 238.199 154.287C238.767 153.718 239.49 153.331 240.277 153.174C241.064 153.017 241.88 153.097 242.622 153.405C243.364 153.713 243.997 154.234 244.443 154.903C244.889 155.572 245.127 156.358 245.127 157.162C245.126 158.241 244.698 159.274 243.937 160.037C243.176 160.799 242.145 161.228 241.069 161.229ZM241.069 153.721C240.39 153.721 239.726 153.923 239.161 154.301C238.596 154.679 238.156 155.217 237.896 155.846C237.637 156.474 237.569 157.166 237.701 157.834C237.834 158.501 238.161 159.114 238.641 159.596C239.121 160.077 239.733 160.405 240.399 160.537C241.065 160.67 241.756 160.602 242.383 160.342C243.011 160.081 243.547 159.64 243.924 159.074C244.301 158.508 244.503 157.843 244.503 157.162C244.502 156.25 244.14 155.375 243.496 154.73C242.852 154.085 241.979 153.722 241.069 153.721Z"
        fill="#3F3D56"
      />
      <path
        d="M137.339 93.6003L126.11 82.5855H75.1587V50.9902H141.963V82.5855H137.231L137.339 93.6003ZM75.783 81.9598H126.365L136.7 92.0979L136.601 81.9598H141.339V51.6159H75.783V81.9598Z"
        fill="#0985E3"
      />
      <path
        d="M16.4117 42.6099L16.5201 31.5952H11.7883V0H78.5926V31.5952H27.6411L16.4117 42.6099ZM12.4127 30.9696H17.1505L17.051 41.1075L27.3864 30.9696H77.9682V0.625648H12.4127V30.9696Z"
        fill="#0985E3"
      />
      <path d="M63.9206 10.0103H23.9629V10.6359H63.9206V10.0103Z" fill="#0985E3" />
      <path
        d="M90.4918 74.1745L84.9395 68.6106C84.1923 67.8619 83.7639 66.8521 83.7444 65.7934C83.7248 64.7348 84.1156 63.7098 84.8347 62.9339C85.5538 62.1581 86.545 61.6918 87.6 61.6332C88.655 61.5746 89.6916 61.9283 90.4918 62.6198C91.292 61.9283 92.3286 61.5748 93.3836 61.6334C94.4385 61.692 95.4297 62.1582 96.1488 62.934C96.8678 63.7099 97.2587 64.7348 97.2392 65.7935C97.2197 66.8521 96.7914 67.8619 96.0443 68.6106L90.4918 74.1745ZM87.826 62.2544C87.1421 62.2539 86.4734 62.4568 85.9046 62.8374C85.3358 63.218 84.8924 63.7591 84.6307 64.3922C84.3689 65.0254 84.3004 65.7222 84.434 66.3943C84.5676 67.0665 84.8971 67.6839 85.381 68.1683L90.4918 73.2898L95.6028 68.1683C96.2508 67.5183 96.6148 66.6371 96.6146 65.7183C96.6144 64.7995 96.2501 63.9184 95.6018 63.2688C94.9534 62.6191 94.0742 62.254 93.1574 62.2538C92.2405 62.2536 91.3611 62.6183 90.7125 63.2677L90.4918 63.4889L90.2711 63.2677C89.9502 62.9458 89.569 62.6905 89.1494 62.5166C88.7298 62.3427 88.2801 62.2536 87.826 62.2544Z"
        fill="#0985E3"
      />
      <path
        d="M109.847 65.0719C109.762 64.9869 109.661 64.9195 109.551 64.8735C109.44 64.8275 109.321 64.8038 109.202 64.8037C109.082 64.8037 108.963 64.8273 108.852 64.8732C108.742 64.9192 108.641 64.9865 108.556 65.0714C108.472 65.1564 108.404 65.2572 108.358 65.3681C108.313 65.4791 108.289 65.598 108.289 65.7181C108.289 65.8382 108.313 65.9571 108.359 66.0681C108.405 66.179 108.472 66.2798 108.557 66.3646L109.847 67.6573L111.137 66.3646C111.222 66.2798 111.289 66.179 111.335 66.0681C111.381 65.9571 111.404 65.8382 111.404 65.7181C111.404 65.598 111.381 65.4791 111.335 65.3681C111.289 65.2572 111.222 65.1564 111.137 65.0714C111.052 64.9865 110.952 64.9192 110.841 64.8732C110.73 64.8273 110.612 64.8037 110.492 64.8037C110.372 64.8038 110.253 64.8275 110.143 64.8735C110.032 64.9195 109.931 64.9869 109.847 65.0719Z"
        fill="#0985E3"
      />
      <path
        d="M104.54 65.0719C104.455 64.9869 104.354 64.9195 104.244 64.8735C104.133 64.8275 104.014 64.8038 103.894 64.8037C103.775 64.8037 103.656 64.8273 103.545 64.8732C103.434 64.9192 103.334 64.9865 103.249 65.0714C103.164 65.1564 103.097 65.2572 103.051 65.3681C103.005 65.4791 102.982 65.598 102.982 65.7181C102.982 65.8382 103.006 65.9571 103.052 66.0681C103.097 66.179 103.165 66.2798 103.25 66.3646L104.54 67.6573L105.83 66.3646C105.914 66.2798 105.982 66.179 106.028 66.0681C106.074 65.9571 106.097 65.8382 106.097 65.7181C106.097 65.598 106.074 65.4791 106.028 65.3681C105.982 65.2572 105.915 65.1564 105.83 65.0714C105.745 64.9865 105.645 64.9192 105.534 64.8732C105.423 64.8273 105.305 64.8037 105.185 64.8037C105.065 64.8038 104.946 64.8275 104.836 64.8735C104.725 64.9195 104.624 64.9869 104.54 65.0719Z"
        fill="#0985E3"
      />
      <path
        d="M107.312 74.7652C105.954 74.7652 104.626 74.3616 103.497 73.6054C102.367 72.8492 101.487 71.7743 100.967 70.5168C100.447 69.2592 100.311 67.8755 100.576 66.5405C100.841 65.2055 101.495 63.9792 102.456 63.0167C103.416 62.0542 104.64 61.3988 105.972 61.1332C107.304 60.8677 108.685 61.004 109.94 61.5248C111.195 62.0457 112.268 62.9278 113.022 64.0596C113.777 65.1914 114.18 66.5219 114.18 67.8831C114.178 69.7077 113.454 71.457 112.166 72.7472C110.879 74.0374 109.133 74.7632 107.312 74.7652ZM107.312 61.6266C106.077 61.6266 104.87 61.9936 103.843 62.681C102.817 63.3685 102.016 64.3456 101.544 65.4889C101.071 66.6321 100.948 67.89 101.189 69.1037C101.43 70.3173 102.024 71.4321 102.897 72.3071C103.77 73.1821 104.883 73.778 106.094 74.0194C107.305 74.2608 108.56 74.1369 109.701 73.6633C110.842 73.1898 111.817 72.3879 112.503 71.359C113.189 70.3301 113.555 69.1205 113.555 67.8831C113.554 66.2244 112.895 64.6341 111.725 63.4612C110.554 62.2883 108.967 61.6285 107.312 61.6266Z"
        fill="#0985E3"
      />
      <path
        d="M124.169 74.7652C122.811 74.7652 121.483 74.3616 120.354 73.6054C119.225 72.8492 118.344 71.7743 117.825 70.5168C117.305 69.2592 117.169 67.8755 117.434 66.5405C117.699 65.2055 118.353 63.9792 119.313 63.0167C120.274 62.0542 121.497 61.3988 122.83 61.1332C124.162 60.8677 125.543 61.004 126.798 61.5248C128.053 62.0457 129.125 62.9278 129.88 64.0596C130.634 65.1914 131.037 66.5219 131.037 67.8831C131.035 69.7077 130.311 71.457 129.023 72.7472C127.736 74.0374 125.99 74.7632 124.169 74.7652ZM124.169 61.6266C122.935 61.6266 121.728 61.9936 120.701 62.681C119.674 63.3685 118.874 64.3456 118.401 65.4889C117.929 66.6321 117.805 67.89 118.046 69.1037C118.287 70.3173 118.882 71.4321 119.755 72.3071C120.628 73.1821 121.74 73.778 122.951 74.0194C124.163 74.2608 125.418 74.1369 126.559 73.6633C127.7 73.1898 128.675 72.3879 129.361 71.359C130.047 70.3301 130.413 69.1205 130.413 67.8831C130.411 66.2244 129.753 64.6341 128.582 63.4612C127.412 62.2883 125.825 61.6285 124.169 61.6266Z"
        fill="#0985E3"
      />
      <path
        d="M126.979 67.2572C127.841 67.2572 128.54 66.5569 128.54 65.693C128.54 64.8292 127.841 64.1289 126.979 64.1289C126.117 64.1289 125.418 64.8292 125.418 65.693C125.418 66.5569 126.117 67.2572 126.979 67.2572Z"
        fill="#0985E3"
      />
      <path
        d="M121.672 67.2572C122.534 67.2572 123.233 66.5569 123.233 65.693C123.233 64.8292 122.534 64.1289 121.672 64.1289C120.81 64.1289 120.111 64.8292 120.111 65.693C120.111 66.5569 120.81 67.2572 121.672 67.2572Z"
        fill="#0985E3"
      />
      <path
        d="M122.224 69.1341C121.587 73.5495 126.641 74.4451 126.135 69.1339C126.165 69.1303 122.427 69.1368 122.224 69.1341Z"
        fill="#0985E3"
      />
      <path
        d="M104.907 70.0503C106.334 71.9734 108.802 72.1461 110.121 70.0503H104.907Z"
        fill="#0985E3"
      />
      <path d="M63.9948 14.7837H24.0371V15.4093H63.9948V14.7837Z" fill="#0985E3" />
      <path d="M63.9948 19.1631H24.0371V19.7887H63.9948V19.1631Z" fill="#0985E3" />
      <path d="M33.4021 42.312H0V42.9377H33.4021V42.312Z" fill="#3F3D56" />
      <path d="M152.651 93.3022H119.249V93.9279H152.651V93.3022Z" fill="#3F3D56" />
    </g>
    <defs>
      <clipPath id="clip1">
        <rect width="247" height="206" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
