import { SVGArrowRight, SVGLogo } from '@shared/SVG'
import { LoginMethod } from '@components/login'
import { useRouter } from 'next/dist/client/router'
import Tooltip from '@shared/Tooltip'
import { useAuthService } from '@/services/auth/auth.service'
import getConfig from 'next/config'
import { fireNextState, SubscribeStateKey } from '@helpers/stateSubscriber'
import { Dispatch, SetStateAction, useRef } from 'react'
import { useNotiContext } from '@shared/Notification'
import { shopHelpPath } from '@/helpers'

type componentProps = {
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

export const HomePageHero: React.FC<componentProps> = ({ setIsLoading }) => {
  const router = useRouter()
  const { notiDispatch } = useNotiContext()
  const { login } = useAuthService(getConfig().publicRuntimeConfig.clientApi)
  const emailRef = useRef<HTMLInputElement>(null)

  let domain = ''

  if (process.browser) {
    domain = window.location.hostname
  }

  async function onLogin() {
    setIsLoading(true)
    const email = emailRef.current?.value
    const result = await login({
      email,
      send_sms: false,
      redirect_url: `https://${domain}/list`
    }).then(
      () => true,
      () => {
        notiDispatch({
          payload: {
            type: 'is-danger',
            title: 'Something went wrong',
            content: "We couldn't find your email!"
          },
          type: 'REMOVE_ALL_AND_ADD'
        })
        return false
      }
    )
    setIsLoading(false)

    result &&
      router.push({ pathname: 'login', query: { method: LoginMethod.CODE } }).then(() => {
        notiDispatch({ type: 'REMOVE_ALL' })
        fireNextState(SubscribeStateKey.LOGIN_FORM, { email })
      })
  }

  return (
    <>
      <style jsx>{`
        .shophelp-welcome {
          font-size: 16px;
          line-height: 32px;
          color: var(--indigo-8);

          .btn-login {
            background: var(--secondary-100);
            border-radius: var(--round-6);
            border: none;
            color: var(--white-100);
            min-width: 120px;
          }

          @media screen and (min-width: 1023px) {
            .btn-login {
              position: absolute;
              right: 8px;
              top: 8px;
              bottom: 8px;
            }
          }

          h1 {
            font-size: 1.5rem;
            line-height: 2rem;
            text-align: center;

            @media screen and (min-width: 1024px) {
              font-size: 2.5rem;
              max-width: 670px;
              line-height: 3.5rem;
              font-weight: 400;
              color: var(--gray-7);
              margin-bottom: 5rem;
              text-align: left;
            }
          }
        }
        @media screen and (max-width: 1023px) {
          .container {
            justify-content: center;
          }

          .btn-login {
            width: 100%;
          }
        }
      `}</style>
      <div className="m-top shophelp-welcome" style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', bottom: '-3rem' }} className="is-hidden-touch">
          {SVGCircle}
        </div>
        <div
          className="is-hidden-desktop is-flex is-justify-content-center"
          style={{ marginBottom: '4.5rem' }}
        >
          <SVGLogo />
        </div>

        <div className="container is-flex">
          <div>
            <h1>
              Where{' '}
              <span className="has-text-weight-semibold" style={{ color: 'var(--gray-8)' }}>
                eCommerce Deals
              </span>{' '}
              exceed{' '}
              <span className="has-text-weight-semibold" style={{ color: 'var(--gray-8)' }}>
                Customer Satisfaction
              </span>
            </h1>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                onLogin()
              }}
              style={{ maxWidth: 500 }}
              className="is-hidden-touch"
            >
              <p>Enter your email to continue</p>
              <div className="is-flex is-align-items-center">
                <div
                  className="control has-icons-right mt-4 mb-4 is-flex is-align-items-center"
                  style={{ width: 425 }}
                >
                  <input
                    className="input"
                    type="email"
                    ref={emailRef}
                    required
                    placeholder="john.doe@gmail.com"
                    style={{
                      paddingRight: '8.5rem',
                      borderRadius: 'var(--round-8)',
                      background: 'var(--indigo-2)',
                      borderColor: 'var(--white-100)',
                      boxShadow: 'none'
                    }}
                  />
                  <button id="button_shophelp_login" className="button btn-login" type="submit">
                    Login <SVGArrowRight className="ml-2" />
                  </button>
                </div>
                <Tooltip
                  containerContent={
                    <figure className="image is-24x24 ml-4 has-pointer">
                      <img src={shopHelpPath('hint.svg')} alt="login hint" />
                    </figure>
                  }
                  backgroundColor={'var(--white-100)'}
                  tooltipContentStyle={{
                    filter: 'drop-shadow(3px 2px 6px rgba(66, 83, 108, 0.1))',
                    left: '1rem',
                    bottom: '-6rem'
                  }}
                  contentColor="var(--indigo-8)"
                  customStyleArrow={{
                    background: 'unset'
                  }}
                >
                  <p style={{ minWidth: 250 }}>
                    Log in with the email address you filled in when checkout
                  </p>
                </Tooltip>
              </div>

              <p>
                Or use{' '}
                <span
                  className="is-link"
                  onClick={() =>
                    router.push({ pathname: 'login', query: { method: LoginMethod.PHONE } })
                  }
                >
                  your phone number
                </span>
              </p>
            </form>
          </div>
          <div className="ml-auto is-hidden-touch">{SVGShopping}</div>
        </div>
        <div className="has-text-centered pr-4 pl-4 is-hidden-desktop">
          <p className="mt-4 has-text-centered" style={{ fontSize: 14, lineHeight: '1.5rem' }}>
            You can make change to your order, check order status, track a shipment, and get instant
            support from our team.
          </p>
          <a
            className="button btn-login"
            style={{ position: 'relative', marginTop: '2.5rem', height: '3rem', minWidth: 180 }}
            // href={getDeeplink()}
            onClick={() => router.push('/detail')}
          >
            Check your order
          </a>
        </div>
      </div>
    </>
  )
}

const SVGCircle = (
  <svg
    width="112"
    height="475"
    viewBox="0 0 112 475"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M111.5 237.5C111.5 368.387 4.27716 474.5 -128 474.5C-260.277 474.5 -367.5 368.387 -367.5 237.5C-367.5 106.613 -260.277 0.5 -128 0.5C4.27716 0.5 111.5 106.613 111.5 237.5Z"
      stroke="#E2E2E2"
    />
    <ellipse cx="-128" cy="237.5" rx="213" ry="211.5" fill="#E2F2FD" fillOpacity="0.5" />
  </svg>
)

const SVGShopping = (
  <svg
    width="370"
    height="347"
    viewBox="0 0 370 347"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M108.26 27.3981C70.2142 37.8479 38.9267 83.6254 39.4117 128.761C39.8873 173.798 72.1402 218.193 99.1287 260.918C126.041 303.647 147.537 344.733 174.744 346.91C201.984 349.055 234.894 312.247 262.125 287.831C289.441 263.421 311.018 251.393 329.603 229.938C348.188 208.484 363.706 177.592 360.072 149.932C356.439 122.273 333.645 97.8632 309.805 84.5452C285.884 71.1347 260.997 68.8216 226.105 55.226C191.213 41.6304 146.343 16.8721 108.26 27.3981Z"
      fill="#E2F2FD"
      fillOpacity="0.5"
    />
    <path
      d="M363.325 293.464L369.384 299.87L354.841 321.695L350.342 317.14L357.266 297.72L363.325 293.464Z"
      fill="black"
    />
    <path
      d="M245.893 273.544L250.901 284.103L238.47 306.254L233.234 304.709L236.667 275.798L245.893 273.544Z"
      fill="black"
    />
    <path
      d="M189.22 123.9C185.133 123.9 181.82 121.071 181.82 117.582C181.82 114.092 185.133 111.263 189.22 111.263C193.307 111.263 196.62 114.092 196.62 117.582C196.62 121.071 193.307 123.9 189.22 123.9Z"
      fill="#D37C59"
    />
    <path
      d="M287.972 124.254C291.301 129.697 364.457 294.107 364.457 294.107L356.182 299.887L259.642 180.324L287.972 124.254Z"
      fill="#0985E3"
    />
    <path
      d="M224.945 84.3599C223.042 86.2212 217.469 93.4599 215.647 94.777C213.826 96.0941 192.934 111.007 189.015 112.324C185.097 113.641 193.424 117.56 193.424 117.56C193.424 117.56 220.641 110.653 222.652 109.118C224.664 107.583 236.586 96.6002 236.586 93.6558C236.586 90.7114 224.945 84.3599 224.945 84.3599Z"
      fill="#D37C59"
    />
    <path
      d="M240.89 70.2094C234.817 62.6714 222.956 80.5503 223.213 83.1791C223.47 85.8078 231.859 96.8345 237.176 96.8345C238.769 96.8345 245.156 75.505 240.89 70.2094Z"
      fill="#FCC486"
    />
    <path
      d="M288.913 125.909C290.34 128.086 296.522 145.502 296.522 151.489C296.522 192.308 243.345 202.899 235.707 202.899C236.81 208.598 248.509 273.321 247.958 274.742C247.406 276.162 237.7 279.063 235.707 278.688C228.098 273.947 203.535 207.934 205.38 198.545C207.972 185.391 248.657 146.917 254.535 143.085C260.413 139.254 288.913 125.909 288.913 125.909Z"
      fill="#0B92F1"
    />
    <path
      d="M253.783 53.3374C212.851 53.3374 244.562 141.388 251.762 146.161C256.517 149.306 286.003 137.344 289.16 125.343C290.24 121.245 276.401 53.3374 253.783 53.3374Z"
      fill="#FED385"
    />
    <path
      d="M195.45 126.877C191.363 126.877 188.05 124.048 188.05 120.559C188.05 117.069 191.363 114.24 195.45 114.24C199.537 114.24 202.85 117.069 202.85 120.559C202.85 124.048 199.537 126.877 195.45 126.877Z"
      fill="#DE8E68"
    />
    <path
      d="M266.847 93.9936C266.747 98.4347 265.449 118.817 263.836 119.432C244.338 126.878 210.573 123.596 204.742 123.15C198.912 122.703 195.174 118.006 195.464 116.891C195.754 115.775 214.092 107.219 245.926 106.212C246.516 102.098 245.104 89.6613 246.559 88.551C248.014 87.4407 266.847 93.9936 266.847 93.9936Z"
      fill="#DE8E68"
    />
    <path
      d="M261.43 57.109C244.495 53.1468 241.456 89.3291 243.083 91.9959C244.709 94.6628 266.286 98.4345 269.101 96.1051C271.916 93.7757 274.27 60.1078 261.43 57.109Z"
      fill="#FED892"
    />
    <path
      d="M249.907 30.8379C250.687 34.2449 256.008 53.7512 255.68 54.7853C255.352 55.8194 248.028 60.3802 245.218 59.8795C245.108 58.3937 243.915 50.4366 241.47 45.6526C239.026 40.8686 249.907 30.8379 249.907 30.8379Z"
      fill="#D37C59"
    />
    <path
      d="M228.222 30.9357C227.306 20.5591 231.447 11.5826 237.472 10.8862C243.497 10.1897 249.123 18.037 250.039 28.4136C250.955 38.7902 246.813 47.7667 240.789 48.4632C234.764 49.1597 229.137 41.3123 228.222 30.9357Z"
      fill="#DE8E68"
    />
    <path
      d="M251.091 30.8377C252.337 30.054 254.701 25.4386 254.701 21.1499C254.701 14.4392 248.856 10.6185 244.914 8.63194C241.932 7.10257 239.397 0 232.606 0C227.189 0 226.828 6.24264 224.336 6.48212C217.878 7.09713 214.254 7.3747 214.254 12.7465C214.254 16.6924 218.676 27.4415 227.698 27.4415C227.698 25.4495 228.283 20.5022 229.248 18.1238C234.67 18.434 239.083 26.4074 246.602 26.3748C248.214 26.3421 251.091 30.8377 251.091 30.8377Z"
      fill="#FD8369"
    />
    <path
      d="M248.252 34.1792C246.409 34.1792 244.914 32.0909 244.914 29.5149C244.914 26.9389 246.409 24.8506 248.252 24.8506C250.096 24.8506 251.591 26.9389 251.591 29.5149C251.591 32.0909 250.096 34.1792 248.252 34.1792Z"
      fill="#DE8E68"
    />
    <path
      d="M150.785 300.012C146.803 300.012 143.575 296.318 143.575 291.761C143.575 287.204 146.803 283.51 150.785 283.51C154.766 283.51 157.994 287.204 157.994 291.761C157.994 296.318 154.766 300.012 150.785 300.012Z"
      stroke="#9A9DA3"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.97401 300.012C5.99227 300.012 2.76443 296.318 2.76443 291.761C2.76443 287.204 5.99227 283.51 9.97401 283.51C13.9558 283.51 17.1836 287.204 17.1836 291.761C17.1836 296.318 13.9558 300.012 9.97401 300.012Z"
      stroke="#9A9DA3"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M180.821 132.799C181.416 130.551 183.199 125.919 183.199 125.919C184.288 125.843 191.869 121.38 191.869 121.38"
      stroke="#9A9DA3"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M127.515 236.285L177.255 123.901C178.772 122.888 184.864 118.409 184.864 118.409"
      stroke="#9A9DA3"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M194.037 121.794L170.468 110.789"
      stroke="#9A9DA3"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.89554 278.693H163.273V240.111C154.774 239.251 33.9709 226.189 33.9709 226.189V142.302L180.821 132.799L163.273 240.122"
      stroke="#9A9DA3"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M175.086 167.866H33.9709"
      stroke="#9A9DA3"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M171.187 191.715H33.9712"
      stroke="#9A9DA3"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M205.969 127.362L194.037 121.794"
      stroke="#9A9DA3"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M159.245 191.715C160.79 186.713 177.188 133.022 177.188 133.022"
      stroke="#9A9DA3"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
