// import { useState } from 'react'
import { shopHelpPath } from '@/helpers'
import { useRouter } from 'next/dist/client/router'
// import { LoginMethod } from '@components/login'

export const NeverLostTrack: React.FC = () => {
  // @ts-ignore
  const router = useRouter()
  // const [phone, setPhone] = useState<string>()

  return (
    <>
      <style jsx>{`
        .subscribe-shophelp {
          background: linear-gradient(180deg, #f0f9ff 0%, rgba(250, 251, 251, 0.0001) 100%);
          border-radius: 0px;
          padding: 40px 16px 20px 16px;

          font-size: 15px;
          line-height: 32px;
          color: #363636;

          h2 {
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          }

          p {
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
          }

          .text-blue {
            color: #0093f5;
          }

          .column:first-child {
            position: relative;
            //&::after {
            //  content: '';
            //  position: absolute;
            //  border-left: 1px solid var(--indigo-6);
            //  right: 0;
            //  top: 0.5rem;
            //  height: 100%;
            //}
          }

          .btn-subscribe {
            background: var(--secondary-100);
            border-radius: 4px;
            border: none;
            min-width: 120px;
            height: 3rem;
            color: var(--white-100);
            width: 343px;
          }

          .shophelp_badge_box {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          @media screen and (max-width: 1023px) {
            h2 {
              font-size: 24px;
              line-height: 32px;
            }

            .btn-subscribe {
              background: #0093f5;
              border-radius: 4px;
              width: 100%;
            }
          }
        }
      `}</style>
      <section className="m-top" id="subscribe-shophelp">
        {/* <figure
          className="image is-hidden-touch"
          style={{ position: 'absolute', bottom: 0, height: 134 }}
        >
          {BottomCircle}
        </figure>
        <figure className="image mr-3 is-hidden-touch" style={{ position: 'absolute', right: 0 }}>
          {TopCircle}
        </figure> */}
        <div className="subscribe-shophelp has-text-centered">
          <h2>Need help?</h2>
          <p className="mt-4 mb-8">
            If you have any questions about your order, <span className="text-blue">ShopHelp</span>{' '}
            would love to hear from you!
          </p>
          <div className="columns is-hidden-touch is-centered">
            {/* <div className="column pt-3 pb-3 has-text-centered">
              <p className="has-text-weight-semibold">Scan QR Code to download</p>
              <figure className="image ml-auto mr-auto mt-6 has-pointer" style={{ maxWidth: 100 }}>
                <img src={shopHelpPath('shophelp_QRCode.jpeg')} alt="Scan QR Code" />
              </figure>
            </div> */}
          </div>
          <div className="has-text-centered mb-8">
            <a
              type="submit"
              className="button btn-subscribe"
              // onClick={() => window.open(getDeeplink())}
              // href={getDeeplink()}
              onClick={() => router.push('/detail')}
            >
              Contact Us
            </a>
          </div>

          <div className="shophelp_badge_box">
            <div>Served by</div>{' '}
            <img className="ml-2 " src={shopHelpPath('shophelp_badge.svg')} alt="shophelp badge" />
          </div>
        </div>
      </section>
    </>
  )
}

// const TopCircle = (
//   <svg
//     width="168"
//     height="332"
//     viewBox="0 0 168 332"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M12.4937 0L168 0V331.905C69.4307 291.514 0 194.615 0 81.5C0 53.0984 4.37718 25.7191 12.4937 0Z"
//       fill="#8DC8F8"
//     />
//   </svg>
// )

// const BottomCircle = (
//   <svg
//     width="195"
//     height="134"
//     viewBox="0 0 195 134"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M194.244 134H-2V0.718983C82.052 12.6959 153.654 63.3102 194.244 134Z"
//       fill="#8DC8F8"
//     />
//   </svg>
// )
